/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 6rem;
  }
  
  .loading div {
    width: 1rem;
    height: 1rem;
    margin: 0.5rem;
    border-radius: 50%;
    background-color: var(--swatch-01);
    animation: loading 1.7s infinite ease-in-out;
  }
  
  .loading div:nth-child(2) {
    animation-delay: -0.4s;
  }
  
  .loading div:nth-child(3) {
    animation-delay: -0.8s;
  }
  
  @keyframes loading {
    0% {
      transform: scale(0);
      background-color: var(--swatch-01);
    }
    40% {
      transform: scale(1);
      background-color: var(--swatch-07);
    }
    80%, 100% {
      transform: scale(0);
      background-color: var(--swatch-10);
    }
  }
  