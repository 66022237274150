/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
#tutorial_event_nav {
    color: var(--swatch-10);

    background: -moz-linear-gradient(top, var(--swatch-01) 0%, var(--swatch-05) 80%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, var(--swatch-01) 0%, var(--swatch-05) 80%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, var(--swatch-01) 0%, var(--swatch-05) 80%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    
    z-index: 0;
}

#tutorial_group {
    color: var(--swatch-04);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#tutorial_group h4,
#tutorial_group h6 {
    margin: 0.75rem 1.5rem;
    text-align: center;
}

#tutorial_group h5 {
    margin: 0 .5rem 1rem .5rem;
    text-align: center;
}

.tutorial_content {
    display: flex;
    justify-content: center;
}

#tutorial_content_main_area {
    flex-grow: 1;
    overflow-y: auto;
}

#tutorial_content_next_button {
    width: calc(100% - 2.4rem);
    border-radius: 0.75rem;
    font-weight: var(--weight-03);
}

/* Welcome */

.tutorial_forward {
    background: var(--swatch-10);
    border-radius: 0.75rem;
    background: var(--swatch-12);
    box-shadow: 0 0.5rem 2rem 0 rgba(31, 38, 135, 0.37);
    border-radius: 0.75rem;
    border: 1px solid rgba(255, 255, 255, 0.18);
    margin: 0.5rem auto;
    padding: 1rem;
    width: fit-content;
}

.tutorial_forward > .primary_action_buttons {
    position: relative;
}

.chat-modal {
    max-width: 33rem;
    margin: auto;
}

/* Search */

#tutorial_content_main_area .search_nav {
    position: relative;
    width: 100%;
}

#tutorial_content_main_area .event_org_info_wrapper, 
#tutorial_content_main_area .event_org_info_item_wrapper,
#tutorial_content_main_area .event_org_info_value {
    margin-top: 0;
}

#tutorial_group .card {
    max-width: 30rem;
}

#tutorial_group .card h5 {
    margin: 0 0 0.75rem 0;
}

#tutorial_group .card .tutorial_forward {
    margin: 0.5rem;
    width: calc(100% - 3rem);
}

/* Conclusion */

.user_profile_image_container {
    display: flex;
    justify-content: center;
}

.profile_image {
    width: 12rem;
    height: 12rem;
    border-radius: 3rem;
    object-fit: cover;
    padding: 0.5rem;
}