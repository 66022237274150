/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
* {
  font-family: var(--font-02);
}

h1, h2, h3, h4, h5, h6, p {
  /* Set Defaults */
  margin: 0;
  margin-left: 1rem;
  margin-bottom: 0.5rem; /* Adds space below headings and paragraphs */
  line-height: 1.5; /* Improves readability */
  font-family: var(--font-02);
  color: var(--swatch-06); /* Can be adjusted to match your branding */
}

h1 {
  font-size: var(--size-01);
  font-family: var(--font-01);
  margin-top: 1rem;
  font-weight: var(--weight-03);
  color: var(--swatch-02);
  line-height: 1.1;
  letter-spacing: -0.03125rem;
}

h2 {
  font-size: var(--size-02);
  font-weight: var(--weight-03);
  color: var(--swatch-04);
  line-height: 1.2;
  letter-spacing: -0.025rem;
}

h3 {
  font-size: var(--size-03);
  line-height: 1.3;
  letter-spacing: -0.01875rem;
}

h4 {
  font-size: var(--size-04);
  line-height: 1.4;
  letter-spacing: -0.0125rem;
}

h5 {
  font-size: var(--size-05);
  font-weight: var(--weight-02);
  line-height: 1.4;
  letter-spacing: -0.00625rem;
}

h6 {
  font-size: var(--size-06);
  font-weight: var(--weight-01);
  color: var(--swatch-06);
  line-height: 1.5;
  letter-spacing: 0rem;
}

p {
  margin-top: 0.5rem;
  color: var(--swatch-04);
  line-height: 1.6;
  letter-spacing: 0.00625rem;
}

a {
  text-decoration: none;
  color: var(--swatch-05);
  display: inline-block;
}

a:hover {
  color: var(--swatch-07);
}

.fa-button > .svg-inline--fa { 

  color: var(--swatch-10)
}

hr {
  border: 1px solid var(--swatch-06)
}

.full_width {
  width: 100%;
}

#main {
  overflow-y: auto;

  width: 100%;

  color: var(--swatch-01);


  background: -moz-linear-gradient(top, var(--swatch-08) 0%, var(--swatch-10) 80%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, var(--swatch-08) 0%, var(--swatch-10) 80%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, var(--swatch-08) 0%, var(--swatch-10) 80%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.nav.page_info {
  justify-content: end;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  
}

.nav.page_info > div {
  padding: 0.25rem;
}

.form-container {
  display: flex;
  justify-content: center;
}

form {
  width: 100%;
  padding: 1rem;
}

.form_button {
  margin: 1rem !important;
}

.form_toggle {
  margin-top: 2rem;
  text-align: center;
  font-size: var(--size-06);
  font-weight: var(--weight-03);
  cursor: pointer;
}

.form_toggle:hover {
  color: var(--swatch-07);
}

.form_help_text {
  font-size: var(--size-06);
  color: var(--swatch-07);
}

.input-container {
  flex-grow: 1;
  margin: 0 1rem; /* Adjust as needed */
}

.input-container label,
.mui-input-container label {
  font-size: var(--size-05);
  font-weight: var(--weight-03);
  color: var(--swatch-05)
}

textarea {
  background: -moz-linear-gradient(to top, var(--swatch-10) 0%, var(--swatch-12) 33%); /* FF3.6-15 */
  background: -webkit-linear-gradient(to top, var(--swatch-10) 0%, var(--swatch-12) 33%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to top, var(--swatch-10) 0%, var(--swatch-12) 33%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

input,
.input-container input,
.input-container select {
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  margin: 0.5rem 0;
  box-sizing: border-box;
  border-radius: 0.5rem;
  
  outline: none;
  border: none;

  background: -moz-linear-gradient(to top, var(--swatch-10) 0%, var(--swatch-12) 33%); /* FF3.6-15 */
  background: -webkit-linear-gradient(to top, var(--swatch-10) 0%, var(--swatch-12) 33%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to top, var(--swatch-10) 0%, var(--swatch-12) 33%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.input-container-input_elements {
  display: flex; 
  align-items: center;
}

.input-container-input_elements_exception > div {
  display: flex;
  justify-content: center;
  align-items: center;
  
  width: 2rem;
  height: 2rem;
  
  padding: 0 0.5rem;

  border: 1px solid var(--swatch-03);
  border-radius: 0.5rem 0 0 0.5rem;

  color: var(--swatch-12);
  background: -moz-linear-gradient(to top, var(--swatch-06) 0%, var(--swatch-07) 33%); /* FF3.6-15 */
  background: -webkit-linear-gradient(to top, var(--swatch-06) 0%, var(--swatch-07) 33%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to top, var(--swatch-06) 0%, var(--swatch-07) 33%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.input-container-input_elements_exception > input {
  border-radius: 0 0.5rem 0.5rem 0;
}

.mui-input-container {
  margin: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
}

.mui-input-container label {
  margin-right: 1rem;
}

& .MuiInputBase-input {
  padding: 0.5rem !important;
}

& .MuiInputBase-root {
  color: var(--swatch-01) !important;
    
  background: -moz-linear-gradient(to top, var(--swatch-10) 0%, var(--swatch-12) 33%); /* FF3.6-15 */
  background: -webkit-linear-gradient(to top, var(--swatch-10) 0%, var(--swatch-12) 33%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to top, var(--swatch-10) 0%, var(--swatch-12) 33%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

  border-radius: 0.5rem !important;
}

.button {
  color: var(--swatch-12);
  background-color: var(--swatch-04);
  margin: 0.5rem;
  
  cursor: pointer;
  user-select: none;
  border-radius: 0.5rem;
  min-width: 5rem;
  padding: 0.7em;
  height: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gradient_button {
  background: -moz-linear-gradient(to top right, var(--swatch-02) 10%, var(--swatch-08) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(to top right, var(--swatch-02) 10%, var(--swatch-08) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to top right, var(--swatch-02) 10%, var(--swatch-08) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.page_button {
  min-width: 1.5rem;
}

.button:hover {
  background-color: var(--swatch-09);
}

.gradient_button:hover {
  background: -moz-linear-gradient(to top right, var(--swatch-06) 10%, var(--swatch-09) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(to top right, var(--swatch-06) 10%, var(--swatch-09) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to top right, var(--swatch-06) 10%, var(--swatch-09) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.button.disabled {
  background-color: var(--swatch-09);
  color: var(--swatch-08);
  cursor: no-drop;
}

.footer {
  font-size: var(--size-05);
  color: var(--swatch-03);
  background-color: var(--swatch-08);
  text-align: center;
  padding: 1rem;
}

.c2a {
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  margin: 1rem;
  border-radius: 1rem;
  background: -moz-linear-gradient(to bottom right, var(--swatch-08) 0%, var(--swatch-10) 80%); /* FF3.6-15 */
  background: -webkit-linear-gradient(to bottom right, var(--swatch-08) 0%, var(--swatch-10) 80%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom right, var(--swatch-08) 0%, var(--swatch-10) 80%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  border: 0.0625rem outset var(--swatch-06);
}

.c2a > * {
  padding: 0;
  margin: 0;
}

.c2a > h6 {
  font-weight: var(--weight-03);
  margin-bottom: 0.5rem;
}

.c2a > h6.no_bottom_margin {
  margin: 0;
}

/* Override Revibe since CSS is dynamically loaded via Termly */
#termly-code-snippet-support h3 {
  color: #021125;
}

@media (max-width: 768px) {
  .logo-block {
      max-width: 100%;
      max-height: 16rem;
  }
}