/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
.card {
  background: var(--swatch-10);
  border-radius: 1rem;

  background: rgba( 255, 255, 255, 0.25 );
  box-shadow: 0 0.5rem 2rem 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 0.25rem );
  -webkit-backdrop-filter: blur( 0.25rem );
  border-radius: 0.75rem;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  margin: 1rem auto;

  max-width: 32rem;
}

.card h3 {
  padding: 0.5rem 0;
}

.card h3,
.card h5 {
  margin: 0;
  text-align: center;
}

.card p {
  margin: 0;
  margin-top: 0.75rem;
  text-align: left;
}

.card .time_label,
.card .time_label_timeline {
  font-size: var(--size-06);
  color: var(--swatch-07);
  text-align: center;
  padding-top: 0.75rem;
}

.card .time_label_timeline svg,
.card .time_label svg {
  margin-right: 0.5rem;
}

.event_image {
  object-fit: cover;
  width: 100.2%;
  position: absolute;
  transform: translate(-0.1%, -0%);

  border-radius: 0.75rem;
  box-shadow: 0 0.25rem 0.25rem 0 rgba( 31, 38, 135, 0.37 );
}

.event_image .screen {
  position: inherit !important;
}

.event_image .screen-image {
  border-radius: 0.75rem;
}

.event_image img,
.event_image video {
  width: 100%;
  border-radius: 0.75rem;
}

.forward {
  background: var(--swatch-10);
  border-radius: 0.75rem;

  background: var(--swatch-12);
  box-shadow: 0 0.5rem 2rem 0 rgba( 31, 38, 135, 0.37 );
  border-radius: 0.75rem;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  margin: 0.5rem;
  padding: 1rem;
  padding-bottom: 1.5rem;

  transform: translate(0%, 16rem);
}

/* Buttons plural because maybe more in the future? */
.primary_action_buttons {
  display: flex;
  justify-content: flex-start;
  position: absolute;
  
  margin-top: -2.75rem;
  margin-left: -2.5rem;
  width: 100%;
}

.primary_action_button {
  color: var(--swatch-04);
  background-image: linear-gradient(
    to bottom right, 
    var(--swatch-12), 
    var(--swatch-08)
  );
  border: 0.25rem outset var(--swatch-04);
  border-radius: 2rem;

  display: flex;
  justify-content: center;
  padding: 0.5rem !important;
}

@keyframes colorToGradient {
  0%, 100% {
    background: var(--swatch-09);
  }
  50% {
    color: var(--swatch-12);
    background: var(--swatch-04);
  }
}

.primary_action_button:hover {
  cursor: pointer;
  color: var(--swatch-06);
  border-color: var(--swatch-06);
  background-image: linear-gradient(
    to top left, 
    var(--swatch-12), 
    var(--swatch-09)
  );
}

.primary_action_total {
  color: var(--swatch-04);
  background: var(--swatch-09);
  border: 0.25rem outset var(--swatch-04);
  border-radius: 2rem;

  padding-left: 1.5rem;
  padding-right: 1rem;
  margin-bottom: 0rem;
  text-align: left;

  width: fit-content;
  font-weight: var(--weight-03);

  box-shadow: 0 0.35rem 0.125rem 0 rgba( 31, 38, 135, 0.37 );
}

.primary_action_total.active {
  animation: colorToGradient 2s;
}

.share_buttons {
  display: flex;
  justify-content: flex-end;
  position: absolute;

  margin-top: 0.5rem;
  width: 100%;
}

.share_buttons_vertical {
  display: flex;
  justify-content: flex-end;
  position: absolute;

  margin-top: calc(-0.5rem - 24px);
  width: 100%;
}

.share_button {
  display: flex;
  justify-content: center;
  background-color: var(--swatch-12);
  margin-left: 0.5rem;
  padding: 0.125rem;
  border-radius: 2rem;

  box-shadow: 0 0.5rem 1rem 0 rgba( 31, 38, 135, 0.37 );
}

.share_button:hover {
  cursor: pointer;
  background-color: var(--swatch-10);
}

.vertical_share_button:hover {
  cursor: pointer;
  background-color: var(--swatch-12);
}

.vertical_share_button.active,
.vertical_share_button.active:hover {
  animation: brightColorToGradient 4s;
}

@keyframes brightColorToGradient {
  0% { background: var(--swatch-12); }
  20% { background: var(--swatch-06); color: var(--swatch-12); }
  70% { background: var(--swatch-06); color: var(--swatch-12); }
  100% { background: var(--swatch-12); }
}

.copied-message {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  
  animation: fadeIn 4s;
}

.copied-message > .error-message {
  margin: 0rem;
  border-radius: 0.75rem 0.75rem 0 0;

  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 0.06125rem solid var(--swatch-11);
  
  box-shadow: 0 0.5rem 1rem 0 rgba( 31, 38, 135, 0.37 );
}

.session_ended-message {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: start;
  z-index: 4;
}

.session_ended-message > a > .error-message {
  width: 100vw;

  background: -moz-linear-gradient(to bottom right, var(--swatch-08) 0%, var(--swatch-06) 80%); /* FF3.6-15 */
  background: -webkit-linear-gradient(to bottom right, var(--swatch-08) 0%, var(--swatch-06) 80%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom right, var(--swatch-08) 0%, var(--swatch-06) 80%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

  color: var(--swatch-12);

  margin: 0;
  padding: 0.5rem 0;
  border-radius: 0;

  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 0.06125rem outset var(--swatch-04);
  
}


@keyframes fadeIn {
  0% { opacity: 0; }
  20% { opacity: 1; }
  70% { opacity: 1; }
  100% { opacity: 0; }
}

.vertical_share_button {
  width: 32px;
  height: 32px;
  align-items: center;

  box-sizing: border-box;
}

.event_org_info_wrapper {
  margin-top: 18rem;
  padding: 1rem;
}

.event_description {
  text-align: left !important;
  word-break: break-word;
}

.event_org_info_item {
  display: flex;
  justify-content: flex-start;
}

.event_org_info_item_wrapper {
  width: 7rem
}

.event_org_info_value {
  flex-grow: 1;
  max-width: 70%;
}

.event_org_info_label {
  background-color: var(--swatch-12);
  font-weight: var(--weight-03);
  box-shadow: 0 0.0625rem 0.25rem 0 rgba( 31, 38, 135, 0.37 );
  padding: 0.0625rem 0.5rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;
}

.interaction_buttons {
  display: flex;
  align-items: center;
}

.button svg {
  margin-right: 0.5rem;
}

@media (max-width: 600px) {
  .event_org_info_value {
    flex-grow: 1;
    max-width: 50%;
  }

  .card {
      margin: 1rem;
  }
}