/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/

.quick_create-widget {
    border: 0.125rem inset var(--swatch-06);
    background: linear-gradient(to bottom, var(--swatch-06) 0%, var(--swatch-03) 80%);
    max-width: 32rem;
    
    margin: 0 0.5rem;
    
    border-radius: 0 0 1rem 1rem;
}

.quick_create-subtext {
    background-color: var(--swatch-09);
    border-radius: 0 0 0.75rem 0.75rem;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    min-height: 3rem;
}

.quick_create-widget-main_button_wrapper {
    font-size: var(--size-03);
    display: flex;
    justify-content: center;
    background: -moz-linear-gradient(to top right, var(--swatch-02) 10%, var(--swatch-08) 90%) !important; /* FF3.6-15 */
    background: -webkit-linear-gradient(to top right, var(--swatch-02) 10%, var(--swatch-08) 90%) !important; /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to top right, var(--swatch-02) 10%, var(--swatch-08) 90%) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.quick_create-widget-main_button_wrapper a {
    width: 100%;
}

.quick_create-widget-main_button_wrapper .button {
    border-radius: 1rem;

    background: -moz-linear-gradient(to top right, var(--swatch-04) 10%, var(--swatch-06) 90%) !important; /* FF3.6-15 */
    background: -webkit-linear-gradient(to top right, var(--swatch-04) 10%, var(--swatch-06) 90%) !important; /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to top right, var(--swatch-04) 10%, var(--swatch-06) 90%) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

    color: var(--swatch-12);
    border: 0.125rem outset var(--swatch-10);
}

.quick_create-widget-main_button_wrapper .button:hover {
    color: var(--swatch-09);
    border: 0.125rem inset var(--swatch-10);
    cursor: pointer;
}

/* IDEA: Refactor connected event group into reusable component */
.gradient-border {
    background: -moz-linear-gradient(to top right, var(--swatch-02) 10%, var(--swatch-08) 90%) !important; /* FF3.6-15 */
    background: -webkit-linear-gradient(to top right, var(--swatch-02) 10%, var(--swatch-08) 90%) !important; /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to top right, var(--swatch-02) 10%, var(--swatch-08) 90%) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    border: 0.0625rem outset var(--swatch-04);
}

.connected-event_group {
    background: -moz-linear-gradient(to top right, var(--swatch-12) 10%, var(--swatch-09) 90%) !important; /* FF3.6-15 */
    background: -webkit-linear-gradient(to top right, var(--swatch-12) 10%, var(--swatch-09) 90%) !important; /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to top right, var(--swatch-12) 10%, var(--swatch-09) 90%) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.connected_vibe_label {
    margin: 0.25rem 0.5rem ;
    background: -moz-linear-gradient(to top right, var(--swatch-10) 10%, var(--swatch-09) 90%) !important; /* FF3.6-15 */
    background: -webkit-linear-gradient(to top right, var(--swatch-10) 10%, var(--swatch-09) 90%) !important; /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to top right, var(--swatch-10) 10%, var(--swatch-09) 90%) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  
    width: fit-content;
  
    color: var(--swatch-04);
  
    font-weight: var(--weight-03);
    box-shadow: 0 0.0625rem 0.25rem 0 rgba( 31, 38, 135, 0.37 );
    padding: 0.0625rem 0.5rem;
    border-radius: 0.5rem;
  
    border: 0.0625rem outset var(--swatch-08);
  
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (min-width: 600px) {
}