/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
.forum_group {
  margin: 1rem auto;
  
  max-width: 32rem;
}

.forum_info_item {
  display: flex;
  justify-content: space-between;
  margin: 0.5rem 1rem;
}

.forum_info_group {
  display: flex;
  justify-content: flex-start;
}

.forum_vibe_label {
    background: -moz-linear-gradient(top, var(--swatch-12) 0%, var(--swatch-10) 80%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, var(--swatch-12) 0%, var(--swatch-10) 80%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, var(--swatch-12) 0%, var(--swatch-10) 80%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    color: var(--swatch-04);
  
    font-weight: var(--weight-03);
    box-shadow: 0 0.0625rem 0.25rem 0 rgba( 31, 38, 135, 0.37 );
    padding: 0.0625rem 0.5rem;
    border-radius: 0.5rem;

    border: 0.0625rem outset var(--swatch-04);

    display: flex;
    justify-content: center;
    align-items: center;
    width: 1rem;
}

.forum_reason_label {
    color: var(--swatch-04);
  
    padding: 0.0625rem 0.5rem;
    border-radius: 0.5rem;

}

.recent_comment_from_forum,
.link_to_forum {
  margin-bottom: 0.5rem;
}

.link_to_forum {
  background: -moz-linear-gradient(top, var(--swatch-07) 0%, var(--swatch-03) 80%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, var(--swatch-07) 0%, var(--swatch-03) 80%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, var(--swatch-07) 0%, var(--swatch-03) 80%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  color: var(--swatch-12);

  font-weight: var(--weight-03);
  box-shadow: 0 0.0625rem 0.25rem 0 rgba( 31, 38, 135, 0.37 );
  padding: 0.0625rem 0.5rem;
  border-radius: 0.5rem;
}

/* IDEA: Make this Forum Widget Code common with the Forum Service  */

.forum-widget {
  border: 0.125rem inset var(--swatch-06);
  border-radius: 1rem;
  margin: 1rem;

  background: -moz-linear-gradient(top, var(--swatch-06) 0%, var(--swatch-03) 80%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, var(--swatch-06) 0%, var(--swatch-03) 80%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, var(--swatch-06) 0%, var(--swatch-03) 80%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

  max-width: 32rem;
}

.forum-widget.is_loading {
  height: 6.5rem;
  display: flex;
  justify-content: center;
}

.forum-widget.entry:hover {
  cursor: pointer;
  border: 0.125rem inset var(--swatch-10);
}

.forum-widget h4,
.forum-widget h5,
.forum-widget h6 {
  margin: 1rem 0;
}

.forum-widget h4,
.forum-widget h5,
.forum-widget h6 {
  color: var(--swatch-10);
  text-align: center;
}

.forum-widget h4 > svg,
.forum-widget h5 > svg {
  margin-right: 0.5rem;
}

.forum-widget-comment_hint {
  padding: 0rem 1rem;
  text-align: center;
}

.forum-widget-comment_hint > div {
  padding-bottom: 0.5rem;
}

.forum-widget-comment_hint > div > svg {
  margin-right: 0.5rem;
}

.revibe_actions {
  padding: 0.75rem;
  display: flex;
  justify-content: center !important;

  color: var(--swatch-10);
  background: -moz-linear-gradient(top, var(--swatch-05) 0%, var(--swatch-02) 80%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, var(--swatch-05) 0%, var(--swatch-02) 80%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, var(--swatch-05) 0%, var(--swatch-02) 80%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.revibe_actions > div {
  margin: 0rem 1.5rem;
}

.revibe_actions > div:hover {
  cursor: pointer;
  color: var(--swatch-09);
}

.quick-comment {
  background-color: var(--swatch-09);
  border-radius: 0 0 0.75rem 0.75rem;
}

.quick-comment-title {
  color: var(--swatch-04);
  font-weight: var(--weight-03);
}

.quick-comment-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  min-height: 3rem;
  /* border:solid rgb(255, 225, 0) 1rem; */
}

.quick-comment-actions svg:hover {
  cursor: pointer;
  color: var(--swatch-06);
}

.search_input-container {
  flex-grow: 1;
  margin: 0 1rem; /* Adjust as needed */

  display: flex;
}

.search_input-container textarea {
  resize: none;
  overflow-y: auto;
  
  max-height: 2rem;

  width: 100%; /* Add this line */
  height: 100%;
  padding: 0.5rem;
  box-sizing: border-box;
  border-radius: 0.75rem; /* Add this line */

  outline: none; /* Add this line */
  border: none; /* Add this line */
}

textarea::-webkit-scrollbar {
  border-radius: 0 0.75rem 0.75rem 0;
}

textarea::-webkit-scrollbar-thumb {
  outline: 0px solid var(--swatch-04);
  background-color: var(--swatch-04);

  border-radius: 0 0.75rem 0.75rem 0;
}

textarea::-webkit-scrollbar-track,
textarea::-webkit-scrollbar-track-piece {
  border-radius: 0.75rem;
}

.local-links {
  max-width: 33rem;
  margin: 0 auto; 
}

@media (min-width: 600px) {
  .forum-widget {
      margin: 1rem auto;
  }
}