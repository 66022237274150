/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
.chart-container {
    max-width: 500px;
    max-height: 400px;
    margin: auto; /* To center the chart if the page width is larger */
}

.d3_bar_chart {
    height: 16rem;
    width: 100%;
}
  
.chart-container rect:hover {
    cursor: pointer;
    opacity: .3;
}

.chart-container > .vibe_table {
    margin: 1rem;
}

.vibe_table_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: var(--weight-03);
    margin-bottom: 1rem;
}


.chart-container-back {
    margin: 0;
}

.chart-container-back:hover {
    cursor: pointer;
    color: var(--swatch-08);
}

.chart-container-back > svg {
    margin-right: 0.25rem;
}

.vibe_reason_group {
    margin-left: 2.25rem;
    margin-bottom: 2rem;
}

.vibe_reason {
    display: flex;
    justify-content: start;
    align-items: center;
}

.vibe_reason > .number {
    background: -moz-linear-gradient(top, var(--swatch-10) 0%, var(--swatch-09) 80%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, var(--swatch-10) 0%, var(--swatch-09) 80%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, var(--swatch-10) 0%, var(--swatch-09) 80%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    color: var(--swatch-04);

    border-radius: 0.25rem;
  
    font-weight: var(--weight-03);
    margin-right: 0.5rem;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.25rem;
} 

.last_updated_timer {
    font-weight: var(--weight-03);
    text-align: center;
    margin: 1rem 1rem 2rem 1rem;
}