/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
.financial-summary-card {
  background: var(--swatch-10);
  border-radius: 1rem;

  background: rgba( 255, 255, 255, 0.25 );
  box-shadow: 0 0.5rem 2rem 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 0.25rem );
  -webkit-backdrop-filter: blur( 0.25rem );
  border-radius: 0.75rem;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  margin: 1rem;

  max-width: 33rem;
}

.financial-summary-card h4,
.financial-summary-card p {
  margin: 0.5rem 1rem;
}

  .summary-header {
    text-align: center;
    padding-bottom: 1rem;
  }
  
  #income-message, 
  #expense-warning,
  #equal {
    text-align: center;
    background-color: var(--swatch-12);
    color: var(--swatch-01);
    margin: 0 1rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
  }

  #amount-difference {
    font-weight: var(--weight-03);
    text-decoration: underline;
  }

  #budget-rollup {
    padding-bottom: 1rem;
  }
  
  .financial-overview {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
  }
  
  .income-section, .expenses-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .expenses-number,
  .income-number {
    background-color: var(--swatch-12);
    padding: 0.5rem 0;
    border-radius: 0.5rem;
    width: 6.5rem;
  }
  
  .cash-flow-story {
    padding-top: 2rem;
    text-align: center;
  }
  
  .suggestion {
    text-align: center;
    margin: 1rem;
    font-style: italic;
  }

  .more-income {
    text-align: center;
    font-size: 5rem;
    padding-bottom: 2rem;
  }

  .two-column-box {
    display: flex;
    justify-content: center;
    text-transform: capitalize;
    margin: 1rem;
  }

  .two-column-box > div {
    width: 50%;
  }

  .cash-flow-item {
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0rem;
    margin: 0.125rem;
    border-radius: 1rem;
    
    background: -moz-linear-gradient(to bottom right, var(--swatch-09) 0%, var(--swatch-10) 80%); /* FF3.6-15 */
    background: -webkit-linear-gradient(to bottom right, var(--swatch-09) 0%, var(--swatch-10) 80%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom right, var(--swatch-09) 0%, var(--swatch-10) 80%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    border: 0.0625rem outset var(--swatch-06);
  }
 
  .fa-button > a > .svg-inline--fa {
      color: var(--swatch-10);
  }

  @media (min-width: 600px) {
    .financial-summary-card {
      margin: 1rem auto;
    }
  }
  