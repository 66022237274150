/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
.swatches {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.swatch {
    margin: 0.5rem;
    width: 8rem;
    height: 8rem;
    position: relative; 
}

.swatch-name {
    position: absolute;
    bottom: 0rem; /* Adjust this value to position the name properly */
    left: 0rem; /* Adjust this value to position the name properly */
    font-size: var(--size-06); /* You can adjust the size using your defined variable */
    color: var(--swatch-12); /* Choose a color that contrasts with your swatch background */
    text-shadow: -1px -1px 0 var(--swatch-01), 1px -1px 0 var(--swatch-01), -1px 1px 0 var(--swatch-01), 1px 1px 0 var(--swatch-01);
    white-space: nowrap; /* Prevents the text from breaking into a new line */
}