/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
.footer-nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  bottom: 0;
  height: 3.0rem;

  background-color: var(--swatch-04);
  z-index: 2; /* You may need to adjust this value based on other elements on your page */

  width: 100vw;
}

.proto-card {
  border-radius: 0.5rem;
  
  margin: 1rem;
  padding: 1rem;
}

.budget-header h1 {
  margin-bottom: 0.5rem;
}

.budget-header p {
  margin-bottom: 1.5rem;
}

.budget-section {
  margin-bottom: 1.5rem;
}

.budget-section h2 {
  color: #333333; /* Adjust text color as needed */
  margin-bottom: 0.5rem;
}

input[type="text"] {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

#skip {
  background-color: #dddddd; /* Light grey background for the 'Skip' button */
}

#go {
  background-color: var(--swatch-06); 
  color: white;
}

.budget-actions {
  display: flex;
  justify-content: space-between;
}

.box1 {
  height: 100%;
}

#save:hover {
  cursor: pointer;
}

/* IDEA: Refactor the v1.0 budget files */
#save > span.errorMessage {
  margin-left: 0.5rem;
  color: var(--swatch-10);
  font-weight: var(--weight-03);
}

/* Responsive adjustments */
@media (min-width: 600px) {
  #quick-page {
    margin: 1rem auto;
    max-width: 33rem;
  }
}