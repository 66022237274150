/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
.stats {
    margin: 0 auto;
    max-width: 33rem;
}

.stats h1 {
    margin: 1rem 1rem 0 1rem;
    text-align: center;
}

.stats h5 {
    margin: 0.125rem 1rem 1rem 1rem;
    text-align: center;
}

.stats h6 {
    margin: 0.125rem 1rem 1rem 1rem;
    text-align: center;
}

/* Top images */

.top_images {
    max-width: 33rem;
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 1rem;
}

.stat_picture {
    width: 6rem;
}

.stat_picture > .event_image {
    position: inherit;
    height: 6rem;
}

.stat_count {
    margin: 0.25rem;
    text-align: center;
    font-size: 0.8rem;
}

/* Stat items (groups) */

.stat_item {
    max-width: 33rem;
}

.stat_item .link_to_forum {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.stat_item i {
    color: var(--swatch-06)
}

.stat_item i span {
    color: var(--swatch-07)
}