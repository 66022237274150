/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
.line_item.input-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 4rem;
    margin: 1rem;
    padding: 1rem;

    border-radius: 0.5rem;
    
    background: -moz-linear-gradient(to top left, var(--swatch-09) 0%, var(--swatch-12) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(to top left, var(--swatch-09) 0%, var(--swatch-12) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to top left, var(--swatch-09) 0%, var(--swatch-12) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.line_item.input-container > .edit_area {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.line_item.input-container > .edit_area > .remove {
    margin-right: 1rem;
}

.line_item.input-container > .edit_area > .remove:hover {
    cursor: pointer;
    color: var(--swatch-06);
}

.line_item.input-container > input,
.line_item-text > .amount {
    text-align: center;
    width: 6rem;
    font-weight: var(--weight-03);
    font-size: var(--size-06);
    
    margin-left: 1rem;
}

.line_item.input-container > input {
    height: auto;
}

.line_item-text > label,
.line_item-text > p {
    margin: 0 !important;
}

.line_item-input-edit {
    display: flex;
    flex-direction: column;
}

.line_item.input-container > input,
.line_item-input-edit > input {
    margin: 0.0625rem !important;
    padding: 0 0.5rem !important;
    border: 1px outset var(--swatch-09) !important;
}

.line_item-input-edit > input.label {
    font-weight: var(--weight-03);
    font-size: var(--size-05);
}

.line_item-input-edit > input.p {
    font-size: var(--size-06);
}

.line_item-input-edit > input.label,
.line_item-input-edit > input.p {
    color: var(--swatch-05);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
    -moz-appearance: textfield; /* For Firefox */
    -webkit-appearance: textfield; /* For older versions of Chrome, Safari */
    appearance: textfield; /* Standard property for modern browsers */
}
  