/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
.query_engine-event_table-hint {
  margin: 0.25rem 1rem;
  color: var(--swatch-04);
  text-align: center;
}

.event_table_container {
  padding: 1rem;
}

.event_table-date_filters {
  display: flex;
}

.event_table-date_filters-label {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin: 0 !important;
}

.event_table-date_filters-label span:last-child {
  font-size: var(--size-06);

  background-color: var(--swatch-12);
  font-weight: var(--weight-03);
  box-shadow: 0 0.0625rem 0.25rem 0 rgba( 31, 38, 135, 0.37 );
  padding: 0.0625rem 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;
}

/* eventTableStyles.css */
.mui-revibe-root {
  border-color: var(--swatch-04) !important;
  outline: 0 !important;
}

.mui-revibe-row {
  background-color: var(--swatch-06) !important;
  color: var(--swatch-10) !important;
}

.mui-revibe-row.Mui-selected {
  background: linear-gradient(to top right, var(--swatch-01) 10%, var(--swatch-06) 90%);

  background-color: var(--swatch-01) !important;
  color: var(--swatch-12) !important;
  font-weight: var(--weight-03) !important;
}

.mui-revibe-row.Mui-hovered {
  background-color: var(--swatch-01) !important;
  color: var(--swatch-10) !important;
}

.mui-revibe-cell {
  cursor: pointer;
  border: 0; /* Custom cell borders */
  border-color: var(--swatch-04) !important;
  outline: 0 !important;
}

.mui-revibe-headers {
  background-color: var(--swatch-12) !important;
  color: var(--swatch-04);
  border-bottom: 1px solid var(--swatch-04) !important; /* Custom cell borders */
}

.mui-revibe-header {
  outline: 0 !important;
}

.mui-revibe-footer {
  border-top: 1px solid var(--swatch-04) !important; /* Custom cell borders */
  background-color: var(--swatch-12) !important;
  border-radius: 0 0 0.25rem 0.25rem !important;
  color: var(--swatch-04);
}

/* IDEA: Refactor: MUI - Override Globally  */

.MuiButtonBase-root {
  color: var(--swatch-04) !important;
}

.MuiPaper-root {
  color: var(--swatch-01) !important;
  border: 0.0625rem outset var(--swatch-07);
  background: linear-gradient(to top right, var(--swatch-10) 10%, var(--swatch-12) 90%);
}

.Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
  border: 0.0625rem inset var(--swatch-04) !important;
}

.MuiDataGrid-columnSeparator {
  color: var(--swatch-10) !important;
}

.MuiPickersYear-yearButton.Mui-selected,
.MuiButtonBase-root.Mui-selected:not(.MuiTab-root),
.MuiClockNumber-root.Mui-selected {
  color: var(--swatch-12) !important;
  background-color: var(--swatch-06) !important;
}

.MuiPickersDay-today {
  border: 0.0625rem inset var(--swatch-04) !important;
}

.MuiButtonBase-root.Mui-disabled {
  color: var(--swatch-09) !important;
}

.MuiTypography-root,
.MuiClockNumber-root {
  color: var(--swatch-07) !important;
}

.MuiTypography-root.Mui-selected {
  color: var(--swatch-01) !important;
}

.MuiTabs-indicator {
  background-color: var(--swatch-04) !important;
}

.MuiClock-pin,
.MuiClockPointer-root {
  background-color: var(--swatch-06) !important;
}

.MuiClockPointer-thumb {
  background-color: var(--swatch-12) !important;
  border: 16px solid var(--swatch-06) !important; /* No rem units here because MUI postions with pixels, awkward */
}

.MuiClock-clock {
  background-color: var(--swatch-10) !important;
  padding: 0.75rem;
}